'use client'

import { useCallback, useEffect, useRef } from 'react'

import { Box, Col, Loader, Row, Section, spacing } from '@fortum/elemental-ui'

import { ContractCardConnector } from '@/shared/components/Cards/ContractCardConnector/ContractCardConnector'
import { CoopNotification } from '@/shared/components/CoopNotification'
import { ForwardLink } from '@/shared/components/ForwardLink'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type {
  CampaignConfigurationEntry,
  PromotedContractsSectionEntry,
} from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import type { EnrichedContractProduct } from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import {
  getContractProductsAndPrices,
  getCurrency,
  getPriceElements,
  mapContractItemToGTM,
  useDeliveryInfo,
} from '@/shared/utils/gtm/gtmHelper'
import { contractItemsEvent } from '@/shared/utils/sendGTMEvent'

import { getActiveCampaignConfig } from '../ContractListSection/utils'

export type PromotedContractsSectionProps = Omit<
  PromotedContractsSectionEntry,
  'campaignConfiguration' | 'contracts' | '__typename' | 'sys'
> & {
  /**
   * Composed data from Common Backend and Shared Contentful.
   */
  contractProducts: EnrichedContractProduct[]
  /**
   * Campaign Id which will be used to pick default contractTemplate from contractProducts.
   */
  defaultCampaignId?: number | null
  campaignsConfigurations?: CampaignConfigurationEntry[]
  /**
   *  GTM listId for the contract list section
   */
  listId: string
}

type ColorVariant = 'default' | 'secondary'

/**
 * Section that present up to three contract templates, title and forward link.
 */
export const PromotedContractsSection = ({
  contractProducts,
  defaultCampaignId,
  title,
  bottomLink,
  colorVariant = 'default',
  campaignsConfigurations,
  listId,
}: PromotedContractsSectionProps) => {
  const theme = useTheme()
  const initialLoad = useRef(true)

  const backgroundColors: Record<ColorVariant, string> = {
    default: theme.colors.backgroundPrimary,
    secondary: theme.colors.backgroundSecondary,
  }

  const href = bottomLink && getLinkEntryUrl(bottomLink)
  const { deliveryArea, deliveryStartDate, estimatedConsumption } = useDeliveryInfo()

  const { setItemListName, setListId } = useGlobalStore((store) => store.gtm)
  const {
    delivery: { deliveryAddressType },
  } = useGlobalStore((store) => store.checkoutFlow)

  useEffect(() => {
    setListId(listId)
  }, [setListId, listId])

  const { coopMembershipId, coopMembershipIdStatus } = useGlobalStore(
    (store) => store.partnerFields,
  )

  const { _isHydrated } = useGlobalStore((store) => store.system)

  const activeCampaignConfiguration = defaultCampaignId
    ? getActiveCampaignConfig(defaultCampaignId, campaignsConfigurations)
    : undefined

  const shouldCoopNotificationRender =
    _isHydrated &&
    activeCampaignConfiguration?.isCoop &&
    (!coopMembershipId ||
      coopMembershipIdStatus === 'invalid' ||
      coopMembershipIdStatus === 'verificationFailed')

  const sendGTMEvent = useCallback(() => {
    const products = getContractProductsAndPrices(
      contractProducts,
      defaultCampaignId,
      campaignsConfigurations,
      estimatedConsumption,
      deliveryStartDate,
      deliveryArea,
    )

    const mappedContractItem = products.map((product) => {
      const priceElements = getPriceElements(
        product.contractProduct,
        deliveryArea,
        estimatedConsumption,
        [],
        deliveryStartDate,
      )
      return mapContractItemToGTM(product.contractProduct, priceElements, deliveryAddressType)
    })

    contractItemsEvent(
      'view_item_list',
      {
        item_list_id: listId,
        item_list_name: title,
        currency: getCurrency(deliveryArea),
      },
      mappedContractItem,
    )
    setItemListName(title || '')
    initialLoad.current = false
  }, [
    campaignsConfigurations,
    contractProducts,
    defaultCampaignId,
    deliveryAddressType,
    deliveryArea,
    deliveryStartDate,
    estimatedConsumption,
    listId,
    setItemListName,
    title,
  ])

  useEffect(() => {
    if (initialLoad.current || deliveryArea) {
      sendGTMEvent()
    }
  }, [sendGTMEvent, initialLoad, deliveryArea])

  return (
    <Section
      pv={spacing.m}
      backgroundColor={backgroundColors[colorVariant as ColorVariant]}
      data-testid="promoted-contracts-section"
    >
      <Box gap={spacing.xs} display="flex" flexDirection="column" justifyContent="center">
        {title && <StyledHeading level={2}>{title}</StyledHeading>}
        {coopMembershipIdStatus === 'verifying' ? (
          <Loader alignSelf="center" />
        ) : (
          shouldCoopNotificationRender && (
            <CoopNotification isApiFail={coopMembershipIdStatus === 'verificationFailed'} />
          )
        )}
        <Row justifyContent="center" gap={{ default: `${spacing.m} 0` }}>
          {contractProducts?.map((contractProduct, index) => (
            <Col s={6} xl={4} display="flex" key={`${contractProduct.cfData.name}_${index}`}>
              <ContractCardConnector
                campaignConfiguration={
                  activeCampaignConfiguration ??
                  getActiveCampaignConfig(
                    contractProduct.cfData.defaultCampaignId,
                    campaignsConfigurations,
                  )
                }
                contractProduct={contractProduct}
                order={index}
                isPurchaseDisabled={Boolean(
                  activeCampaignConfiguration?.isCoop && coopMembershipIdStatus !== 'valid',
                )}
              />
            </Col>
          ))}
        </Row>
        {bottomLink?.label && href && (
          <Box display="flex" justifyContent="center">
            <ForwardLink
              data-testid="promoted-contracts-section-link"
              color={theme.colors.primary}
              href={href}
            >
              {bottomLink?.label}
            </ForwardLink>
          </Box>
        )}
      </Box>
    </Section>
  )
}
